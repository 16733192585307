import { Input } from "@angular/core";
import { ChangeDetectionStrategy } from "@angular/core";
import { Component } from "@angular/core";
import { HelpDialogDirective } from "src/app/common/directives/help-dialog.directive";

/**
 * Компонент плашки с иконкой информации.
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "notification-with-icon",
    templateUrl: "./notification-with-info-icon.component.html",
    styleUrls: ["./notification-with-info-icon.component.scss"],
})
export class NotificationWithInfoIconComponent extends HelpDialogDirective {
    //region Inputs

    /**
     * Текст плашки.
     */
    @Input()
    text: string;

    /**
     * Название иконки.
     */
    @Input()
    iconName: string;

    /**
     * Стиль уведомления с иконкой.
     */
    @Input()
    level: "warn" | "alert" | "info";

    /**
     * Цвет уведомления с иконкой.
     */
    @Input()
    customColor: string = null;

    //endregion
    //region Getters

    /**
     * Возвращает цвет уведомления.
     */
    get backgroundColor(): string {

        return this.customColor ? this.customColor : this.getLevelColor();
    }

    //endregion
    //region Private

    /**
     * Возвращает цвет иконка на основе стиля уведомления.
     * @private
     */
    private getLevelColor(): string {

        switch (this.level) {

            case "alert":
                return "#f44336";

            case "warn":
                return "#ffa500";

            case "info":
                return "#3f51b5";
        }
    }

    //endregion
}
