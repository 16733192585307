var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HelpDialogDirective } from "src/app/common/directives/help-dialog.directive";
/**
 * Компонент плашки с иконкой информации.
 */
var NotificationWithInfoIconComponent = /** @class */ (function (_super) {
    __extends(NotificationWithInfoIconComponent, _super);
    function NotificationWithInfoIconComponent() {
        //region Inputs
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Цвет уведомления с иконкой.
         */
        _this.customColor = null;
        return _this;
        //endregion
    }
    Object.defineProperty(NotificationWithInfoIconComponent.prototype, "backgroundColor", {
        //endregion
        //region Getters
        /**
         * Возвращает цвет уведомления.
         */
        get: function () {
            return this.customColor ? this.customColor : this.getLevelColor();
        },
        enumerable: true,
        configurable: true
    });
    //endregion
    //region Private
    /**
     * Возвращает цвет иконка на основе стиля уведомления.
     * @private
     */
    NotificationWithInfoIconComponent.prototype.getLevelColor = function () {
        switch (this.level) {
            case "alert":
                return "#f44336";
            case "warn":
                return "#ffa500";
            case "info":
                return "#3f51b5";
        }
    };
    return NotificationWithInfoIconComponent;
}(HelpDialogDirective));
export { NotificationWithInfoIconComponent };
