/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./processing-errors-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./processing-errors-dialog.component";
var styles_ProcessingErrorsDialogComponent = [i0.styles];
var RenderType_ProcessingErrorsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProcessingErrorsDialogComponent, data: {} });
export { RenderType_ProcessingErrorsDialogComponent as RenderType_ProcessingErrorsDialogComponent };
function View_ProcessingErrorsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-h1 errors-dialog__group-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.titleKey)); _ck(_v, 1, 0, currVal_0); }); }
function View_ProcessingErrorsDialogComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" ..., "]))], null, null); }
function View_ProcessingErrorsDialogComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [", "]))], null, null); }
function View_ProcessingErrorsDialogComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_9)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = !_v.parent.context.last; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ProcessingErrorsDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_7)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_8)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCurrentRowIndexFirstHidden(_v.context.index, _v.parent.parent.parent.context.$implicit.rowIndexes.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isCurrentRowIndexVisible(_v.context.index, _v.parent.parent.parent.context.$implicit.rowIndexes.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ProcessingErrorsDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [": "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_6)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.rowIndexes; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ProcessingErrorsDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "errors-dialog__error-in-group"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "errors-dialog__error-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "."])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_5)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "errors-dialog__error-in-group"; var currVal_1 = _co.getFontSize(); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.parent.context.$implicit.rowIndexes; _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = (_v.parent.context.index + 1); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.parent.context.$implicit.messageKey, _v.parent.context.$implicit.messageArgs)); _ck(_v, 5, 0, currVal_3); }); }
function View_ProcessingErrorsDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.errors.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProcessingErrorsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_3)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.errors.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.errors; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProcessingErrorsDialogComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "errors-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "errors-dialog__header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "errors-dialog__groups"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProcessingErrorsDialogComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "errors-dialog__divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "errors-dialog__buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "button", [["class", "errors-dialog__button"], ["color", "primary"], ["mat-raised-button", ""], ["matDialogClose", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dialogRef.close(i1.ɵnov(_v, 12).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(12, 606208, null, 0, i9.MatDialogClose, [[2, i9.MatDialogRef], i1.ElementRef, i9.MatDialog], { _matDialogClose: [0, "_matDialogClose"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "errors-dialog__button"], ["color", "warn"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.okClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(16, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"], color: [1, "color"] }, null), (_l()(), i1.ɵted(17, 0, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.processingErrorsGroups; _ck(_v, 7, 0, currVal_1); var currVal_5 = "primary"; _ck(_v, 11, 0, currVal_5); var currVal_6 = ""; _ck(_v, 12, 0, currVal_6); var currVal_10 = _co.isSavingLocked; var currVal_11 = "warn"; _ck(_v, 16, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("operator.checkDlg.header")); _ck(_v, 2, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 11).disabled || null); var currVal_3 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); var currVal_4 = (i1.ɵnov(_v, 12).ariaLabel || null); _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4); var currVal_7 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("operator.checkDlg.close")); _ck(_v, 13, 0, currVal_7); var currVal_8 = (i1.ɵnov(_v, 16).disabled || null); var currVal_9 = (i1.ɵnov(_v, 16)._animationMode === "NoopAnimations"); _ck(_v, 15, 0, currVal_8, currVal_9); var currVal_12 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("operator.checkDlg.ok")); _ck(_v, 17, 0, currVal_12); }); }
export function View_ProcessingErrorsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "processing-errors-dialog", [], null, null, null, View_ProcessingErrorsDialogComponent_0, RenderType_ProcessingErrorsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i10.ProcessingErrorsDialogComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], null, null); }
var ProcessingErrorsDialogComponentNgFactory = i1.ɵccf("processing-errors-dialog", i10.ProcessingErrorsDialogComponent, View_ProcessingErrorsDialogComponent_Host_0, {}, {}, []);
export { ProcessingErrorsDialogComponentNgFactory as ProcessingErrorsDialogComponentNgFactory };
