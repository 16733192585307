<div [formGroup]="formGroup" class="company">
    <h1 *ngIf="showHeader" class="mat-h1 company__header">
        {{headerName}}
    </h1>
    <mat-form-field class="company__field">
        <input
            matInput
            formControlName="name"
            required
            [placeholder]="'common.company.name'| translate"
        />
    </mat-form-field>
    <br/>
    <mat-form-field class="company__field">
        <input
            matInput
            formControlName="shortName"
            [placeholder]="'common.company.shortName'| translate"
        />
    </mat-form-field>
    <br/>
    <mat-form-field *ngIf="kazakhCompany || !foreign" class="company__field">
        <input
            matInput
            formControlName="inn"
            [placeholder]="(kazakhCompany && 'common.company.iin' || 'common.company.inn') | translate"
        />
    </mat-form-field>
    <br/>
    <mat-form-field *ngIf="!foreign && !kazakhCompany" class="company__field">
        <input
            matInput
            formControlName="kpp"
            [placeholder]="'common.company.kpp'| translate"
        />
    </mat-form-field>
    <mat-form-field *ngIf="foreign && !kazakhCompany" class="company__field">
        <input
            matInput
            formControlName="taxId"
            [placeholder]="'common.companyCreator.taxId'| translate"
        />
    </mat-form-field>
    <single-select
        class="company__field"
        formControlName="country"
        required
        [dontOpenOnEnterKey]="true"
        [placeholder]="'common.companyCreator.country'| translate"
        [options]="foreign && countriesAsOptions || rusAndKazCountriesAsOptions"
        [searchEnabled]="true"
        [formatOption]="formatCountry"
        [clearBtnEnabled]="false"
    >
    </single-select>
    <mat-form-field *ngIf="foreign" class="company__field">
        <input
            matInput
            formControlName="address"
            [placeholder]="'common.companyCreator.address'| translate"
        />
    </mat-form-field>
    <p class="company__error-message">
        <span *ngIf="errorData">{{getErrorMessage()}}</span>
        <span *ngIf="!errorData">&nbsp;</span>
    </p>
    <div class="company__button-wrapper">
        <button
            class="company__create-button"
            mat-flat-button
            (click)="submitHandler()"
            [disabled]="formGroup.invalid || loading"
        >
            {{submitButtonName}}
        </button>
    </div>
    <mat-spinner
        *ngIf="loading"
        class="company__button-spinner"
        diameter="16"
    ></mat-spinner>
</div>
