import { defaultNotificationUrls } from "src/environments/notification-urls.model";
import { defaultOperatorBackgroundEnvironment } from "src/environments/operator-background.model";
import { OperatorEnvironment } from "src/environments/operator-environment.model";

/**
 * Test окружение.
 */
export const environment: OperatorEnvironment = {
    production: false,
    operatorServerUrl: "https://local.mbo30.ru",
    notificationUrls: defaultNotificationUrls,
    pagedTableView: false,
    operatorBackgroundEnvironment: defaultOperatorBackgroundEnvironment,
    amountModifierTypes: [
        "Round off",
        "TCS",
        "TDS",
        "Discount",
    ],
    currencyOfCountries: {
        "GUY": "GYD",
        "ABW": "AWG",
        "BDI": "BIF",
        "VUT": "VUV",
        "GBR": "GBP",
        "HUN": "HUF",
        "VNM": "VND",
        "HTI": "HTG",
        "GMB": "GMD",
        "GTM": "GTQ",
        "GIN": "GNF",
        "GIB": "GIP",
        "HND": "HNL",
        "HKG": "HKD",
        "DNK": "DKK",
        "DJI": "DJF",
        "DOM": "DOP",
        "EGY": "EGP",
        "ISR": "ILS",
        "IND": "INR",
        "IDN": "IDR",
        "JOR": "JOD",
        "IRQ": "IQD",
        "IRN": "IRR",
        "ISL": "ISK",
        "CPV": "CVE",
        "KAZ": "KZT",
        "CYM": "KYD",
        "KHM": "KHR",
        "CAN": "CAD",
        "QAT": "QAR",
        "KEN": "KES",
        "KGZ": "KGS",
        "PRK": "KPW",
        "CHN": "CNY",
        "COL": "COP",
        "COM": "KMF",
        "CRI": "CRC",
        "CUB": "CUP",
        "KWT": "KWD",
        "LAO": "LAK",
        "LSO": "LSL",
        "LBR": "LRD",
        "LBN": "LBP",
        "LBY": "LYD",
        "MUS": "MUR",
        "SGP": "SGD",
        "MAC": "MOP",
        "MKD": "MKD",
        "MWI": "MWK",
        "MYS": "MYR",
        "MDV": "MVR",
        "MAR": "MAD",
        "MEX": "MXN",
        "MDA": "MDL",
        "MNG": "MNT",
        "MMR": "MMK",
        "NAM": "NAD",
        "NPL": "NPR",
        "NGA": "NGN",
        "NIC": "NIO",
        "NZL": "NZD",
        "NOR": "NOK",
        "ARE": "AED",
        "OMN": "OMR",
        "SHN": "SHP",
        "PAK": "PKR",
        "PNG": "PGK",
        "PRY": "PYG",
        "PER": "PEN",
        "KOR": "KRW",
        "RUS": "RUB",
        "RWA": "RWF",
        "SLV": "SVC",
        "WSM": "WST",
        "SAU": "SAR",
        "SWZ": "SZL",
        "SYC": "SCR",
        "SYR": "SYP",
        "SOM": "SOS",
        "USA": "USD",
        "SLE": "SLL",
        "THA": "THB",
        "TZA": "TZS",
        "TON": "TOP",
        "TTO": "TTD",
        "TUN": "TND",
        "UGA": "UGX",
        "UZB": "UZS",
        "URY": "UYU",
        "FJI": "FJD",
        "PHL": "PHP",
        "FLK": "FKP",
        "CZE": "CZK",
        "CHL": "CLP",
        "CHE": "CHF",
        "SWE": "SEK",
        "LKA": "LKR",
        "ERI": "ERN",
        "ZAF": "ZAR",
        "SSD": "SSP",
        "JAM": "JMD",
        "BEL": "EUR",
        "CYP": "EUR",
        "HRV": "EUR",
        "DEU": "EUR",
        "GRC": "EUR",
        "IRL": "EUR",
        "ITA": "EUR",
        "FIN": "EUR",
        "FRA": "EUR",
        "EST": "EUR",
        "LTU": "EUR",
        "LUX": "EUR",
        "MLT": "EUR",
        "NLD": "EUR",
        "PRT": "EUR",
        "MNE": "EUR",
        "LVA": "EUR",
        "ESP": "EUR",
        "SVK": "EUR",
        "SVN": "EUR",
        "AUT": "EUR",
        "JPN": "JPY",
    },
    companySearchMinLength: 3,
    failedSentDocumentRetryAttempts: 3,
};
